<template>
  <div>
    <b-button
      variant="light"
      @click="signIn()"
    >
      <img
        src="/icons8_google_1.ico"
        alt="icons8_google_1 icon"
      >
    </b-button>
  </div>
</template>
<script>
import { gapi } from 'gapi-script'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

import {
  BButton,
} from 'bootstrap-vue'

const GoogleAccountRepository = RepositoryFactory.get('googleaccount')

export default {
  components: {
    BButton,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      showError: false,
      form: {},
    }
  },
  methods: {
    signIn() {
      this.loading = true
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        }).then(() => {
          const auth2 = gapi.auth2.getAuthInstance()
          auth2.signIn().then(googleUser => {
            const idToken = googleUser.getAuthResponse().id_token
            const profile = googleUser.getBasicProfile()
            const email = profile.getEmail()
            localStorage.setItem('email', email)
            localStorage.setItem('_token', idToken)
            this.form = {
              email,
              token: idToken,
              provider: 'google',
            }
            this.sendUserDetails(this.form)
          }).catch(error => {
            this.showErrorMessage(`Failed to sign in with Google: ${error.error}`)
          })
        }).catch(error => {
          this.showErrorMessage(error.error)
        })
      }).catch(error => {
        this.showErrorMessage(error.error)
      })
      this.loading = false
    },
    async sendUserDetails(payload) {
      this.loading = true
      try {
        const res = await GoogleAccountRepository.sendGoogleAuthenticatedUser(payload)
        if (res.status === 200) {
          if (res.data.isVerified && !res.data.hasBusiness) {
            this.showSuccessMessage(res.data.message)
            localStorage.setItem('isEmailVerified', res.data.isVerified)
            this.$router.push('/buisness-details')
          } else if (res.data.domain.length > 0) {
            const email = localStorage.getItem('email')
            const url = `${(res.data.domain)}/login?email=${encodeURIComponent(email)}&token=${res.data.token}`
            window.location.href = url
          }
        }
      } catch (error) {
        this.showErrorMessage(error)
      }
      this.loading = false
    },
  },
}
</script>
